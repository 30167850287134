!function($){

    var DoneTyping = function (element, options) {
        if ( typeof(options) == 'function' ) options = { success: options };
        this.element = element;
        this.$element = $(element);
        this.options = $.extend({}, $.fn.doneTyping.defaults, options);
        this.bindEvents();
        this.last_val = undefined;
    }

    DoneTyping.prototype = {

        // constructor: DoneTyping

        bindEvents: function () {
            this.$element
                .on('blur',     $.proxy(this.blur, this))
                .on('input',     $.proxy(this.input, this))
                .on('keydown', $.proxy(this.keydown, this));
        }

        , keydown: function (e) {
            switch(e.keyCode) {
                case 37:  // Left arrow
                case 38:  // Up arrow
                case 39:  // Right arrow
                case 40:  // Down arrow
                case 17:  // Ctrl
                case 18:  // Alt
                case 91:  // Command
                case 9:   // Tab
                    break;
                case 13:  // Enter
                    break;
                case 27:  // Escape
                    break;
                default:
                    this.run();
            }
        }

        , run: function() {
            var self = this;
            if ( this.defer ) clearTimeout(this.defer);
            this.defer = setTimeout(function() {
                var new_val = self.trim( self.$element.val() );
                if ( self.last_val != new_val ) {
                    self.last_val = self.trim(self.$element.val());
                    if ( self.options.success ) self.options.success.call(self.element, self.$element.val(), self.options);
                }
            }, this.options.delay);
        }

        , blur: function (e) {
            if ( this.options.blur ) this.options.blur.call(this.element, e, this.options);
            this.run();
        }

        , input: function(e) {
            this.run();
        }

        , trim: function(s) {
            return s.replace(/^\s+/, '').replace(/\s+$/, '');
        }
    }

    // jQuery plugin definition
    $.fn.doneTyping = function(opt) {
        return this.each(function() {
            var $this = $(this),
                data = $this.data('doneTyping'),
                options = opt;
            if ( !data ) $this.data('doneTyping', (data = new DoneTyping(this, options)));
        });
    }

    // Default options
    $.fn.doneTyping.defaults = {
        delay: 500
    }

}(window.jQuery);



function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(emailAddress);
};


function timerCountDown() {
    var interval = setInterval(function() {
        var timer = $('#time-left').html().split(':');
        var minutes = parseInt(timer[0],10);
        var seconds = parseInt(timer[1],10);
        --seconds;
        minutes = (seconds < 0) ? --minutes : minutes;
        if (minutes < 0) clearInterval(interval);
        seconds = (seconds < 0) ? 59 : seconds;
        seconds = (seconds < 10) ? '0' + seconds : seconds;
        minutes = (minutes < 10) ?  '0' + minutes : minutes;
        $('#time-left').html(minutes + ':' + seconds);
        if (minutes == 0 && seconds == 0) clearInterval(interval);
    }, 1000);
};




function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}
